import React from 'react';
import './App.scss';
import Header from '../components/Header/Header';
import Contact from '../components/Contact/Contact';
import About from '../components/About/About';
import WorkExperience from '../components/WorkExperience/WorkExperience';
import Education from '../components/Education/Education';

const App: React.FC = () => {
  return (
    <div className="app">
      <Header />
      <Contact />
      <About />
      <WorkExperience />
      <Education />
    </div>
  );
};

export default App;
