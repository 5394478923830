import React from 'react';
import './Header.scss';
import photo from '../../assets/images/me.jpg';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="text">
          <h1>Marshall Offutt</h1>
          <p>Software Engineer passionate about crafting clean, scalable solutions.</p>
        </div>
        <div className="image">
          <img src={photo} alt="Profile of Marshall" />
        </div>
      </div>
    </header>
  );
};

export default Header;
