import React from 'react';
import './WorkExperience.scss';

const WorkExperience: React.FC = () => {
  return (
    <section className="work-experience">
      <h2>Work Experience</h2>
      <ul>
        <li>
          <strong>2023–Present:</strong> Software Engineer at{' '}
          <a
            href="https://bloomerang.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bloomerang
          </a>
        </li>
        <li>
          <strong>2019–2023:</strong> Software Developer at{' '}
          <a
            href="https://www.experian.com/healthcare/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Experian Health
          </a>
        </li>
        <li>
          <strong>2018–2019:</strong> Apprentice Software Developer at{' '}
          <a
            href="https://nashvillesoftwareschool.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nashville Software School
          </a>
        </li>
      </ul>
    </section>
  );
};

export default WorkExperience;
