import React from 'react';
import './Contact.scss';

const Contact: React.FC = () => {
  return (
    <section className="contact">
      <a href="https://github.com/marshalloffutt" target="_blank" rel="noopener noreferrer">
        GitHub
      </a>
      <a href="https://linkedin.com/in/marshalloffutt" target="_blank" rel="noopener noreferrer">
        LinkedIn
      </a>
      <a href="mailto:marshall@gmail.com">Email</a>
    </section>
  );
};

export default Contact;
