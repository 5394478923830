import React from 'react';
import './About.scss';
import moment from 'moment';
import { EMPLOYER_NAME, JOB_TITLE } from '../../lib/constants';

const About: React.FC = () => {
  const years: number = moment().diff('2019-08-01', 'years');

  const ABOUT_ME_TEXT: string = `I’m a ${JOB_TITLE} at ${EMPLOYER_NAME} with over ${years} years of
      enterprise-level experience. I love collaborating, team-building, and pursuing the shared
      goal of creating great software. With a passion for well-thought-out designs and continuous
      learning, software development has become both my career and my hobby, allowing me to indulge
      my curious nature and love for tinkering.`;

  return (
    <section className="about">
      <h2>About Me</h2>
      <p className="about-me-text">{ABOUT_ME_TEXT}</p>
    </section>
  );
};

export default About;
