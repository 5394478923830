import React from 'react';
import './Education.scss';

const Education: React.FC = () => {
  return (
    <section className="education">
      <h2>Education</h2>
      <ul>
      <li>
          <strong>Nashville Software School:</strong> Full-stack Software Development Bootcamp
        </li>
        <li>
          <strong>Louisiana State University:</strong> BS, Construction Management
        </li>
      </ul>
    </section>
  );
};

export default Education;
